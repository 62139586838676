function loadVideos() {
    $(".hero-video__video").each(function(idx, element) {
        if ($(window).width() >= 768 && !$(element).hasClass('loaded')) {
            $(element).addClass('loaded');
            $(element)[0].play();
        }
    })
}
$(document).ready(function() {
    loadVideos();
    var debouncedLoadVideos = _.debounce(loadVideos, 300);
    $(window).on('resize', debouncedLoadVideos);
});

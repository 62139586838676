$(function () {
    $(".video-cards-carousel").slick({
       dots: false,
       slidesToShow: 1,
       infinite: true,
       speed: 300,
       variableWidth: true,
       swip: false,
       centerMode: true,
    });
});

document.addEventListener('DOMContentLoaded', function () {

    const marquee = document.querySelector('div.marquee.parbase');

    if (marquee) {
        let displayWidth = window.innerWidth;
        let blockSlide = [];

        function populateArray (marqueeID) {
            // Marquee variables:
            const block = document.querySelector('#' + marqueeID + ' .marquee-block'); // Each individual block
            const blocks = document.getElementById(marqueeID);
            const blockText = document.querySelector('#' + marqueeID + ' .marquee-block').innerHTML; // List item value
            let blocksWidth = block.offsetWidth; // Width of each individual block
            let visibleSlides = Math.round(displayWidth / blocksWidth);
            const arrLength = visibleSlides + 2; // This is the total number of blocks and partial blocks, plus one. This makes the array slightly wider than the screen, and accounts for whitespace.
            const blockGuts = []; // This is the array that holds all of the blocks.

            for (i = 0; i < arrLength; i++) {
                blockGuts.push('<li class="marquee-block">' + blockText + '</li>');
            }

            blocks.innerHTML = blockGuts.join('');

            blockSlide.push(blocksWidth);
        }

        const marquees = Array.from(document.querySelectorAll('ul.marquee-slider'));

        marquees.forEach(function (el, i) {
            let speed = 20;
            let pos = 0;

            let marqueeID = 'm' + i;
            el.setAttribute('id', marqueeID);

            if (i === 0) {
                const firstMarquee = document.getElementById('marquee-instance');
                firstMarquee.classList.add('first-marquee');
            }

            populateArray(marqueeID);

            if (i % 2 !== 0) {
                setInterval(frameRight, speed);
                el.style.flexDirection = 'row-reverse';
            } else {
                setInterval(frame, speed);
            }

            function frame () {
                if (pos == -blockSlide[i]) {
                    blockSlide[i] = pos * -1;
                    pos = 0;
                    frame();
                } else {
                    pos--;
                    el.style.transform = 'translateX(' + pos + 'px';
                }
            }

            function frameRight () {
                if (pos == blockSlide[i]) {
                    blockSlide[i] = pos * 1;
                    pos = 1;
                    frame();
                } else {
                    pos++;
                    el.style.transform = 'translateX(' + pos + 'px';
                }
            }
        })
    }
});
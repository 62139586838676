window.Newsletter = (function () {
    const headers = {
        "accept": "application/json",
        "content-type": "application/json",
        "revision": klaviyoConfig.apiVersion
    };

    return {
        subscribe(data) {
            if (!hscoData.klaviyoEnabled) {
                return false;
            }

            const deferred = $.Deferred();

            // create user if needed
            let payload = {
                "data": {
                    "type": "profile",
                    "attributes": {
                        "email": data.email,
                        "properties": {
                            "locale": data.locale
                        }
                    }
                }
            };

            if (data.source) {
                payload.data.attributes.properties.source = data.source;
            }
            if (data.gender) {
                payload.data.attributes.properties.gender = data.gender;
            }
            if (data.givenName) {
                payload.data.attributes.properties.first_name = data.givenName;
            }
            if (data.familyName) {
                payload.data.attributes.properties.last_name = data.familyName;
            }

            $.ajax({
                type: 'POST',
                url: "https://a.klaviyo.com/client/profiles/?company_id=" + klaviyoConfig.siteId,
                traditional: true,
                datatype: 'json',
                data: JSON.stringify(payload),
                headers: headers,
                success: function() {
                    // subscribe the profile
                    payload = {
                        "data": {
                            "type": "subscription",
                            "attributes": {
                                "profile": {
                                    "data": {
                                        "type": "profile",
                                        "attributes": {
                                            "email": data.email
                                        }
                                    }
                                }
                            },
                            "relationships": {
                                "list": {
                                    "data": {
                                        "type": "list",
                                        "id": klaviyoConfig.listId
                                    }
                                }
                            }
                        }
                    };

                    $.ajax({
                        type: 'POST',
                        url: "https://a.klaviyo.com/client/subscriptions/?company_id=" + klaviyoConfig.siteId,
                        traditional: true,
                        datatype: 'json',
                        data: JSON.stringify(payload),
                        headers: headers,
                        success: function() {
                            return deferred.resolve({
                                success: true,
                                responseText: "You've been added to our mailing list."
                            });
                        },
                        error: function() {
                            return deferred.reject({
                                success: false,
                                responseText: "We're unable to add you to our list. Please try again later."
                            });
                        }
                    });
                },
                error: function() {
                    return deferred.reject({
                        success: false,
                        responseText: "We're unable to add you to our list. Please try again later."
                    });
                }
            });

            return deferred.promise();
        },

        subscribeBackInStock(data) {
            if (!hscoData.klaviyoEnabled) {
                return false;
            }

            const deferred = $.Deferred();

            const payload = {
                "data": {
                    "type": "back-in-stock-subscription",
                    "attributes": {
                        "profile": {
                            "data": {
                                "type": "profile",
                                "attributes": {
                                    "email": data.email,
                                }
                            }
                        },
                        "channels": ["EMAIL"],
                    },
                    "relationships": {
                        "variant": {
                            "data": {
                                "type": "catalog-variant",
                                "id": "$shopify:::$default:::" + data.shopifyVariantId
                            }
                        }
                    }
                }
            };

            $.ajax({
                type: 'POST',
                url: "https://a.klaviyo.com/client/back-in-stock-subscriptions/?company_id=" + klaviyoConfig.siteId,
                traditional: true,
                datatype: 'json',
                data: JSON.stringify(payload),
                headers: headers,
                success: function() {
                    return deferred.resolve({
                        success: true
                    });
                },
                error: function() {
                    return deferred.reject({
                        success: false
                    });
                }
            });

            return deferred.promise();
        }
    }
})()

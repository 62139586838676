$(function(){
    $(document).ready(function() {
        $("body").on('click', '.language-selector-link', function(e) {
            var $element = $(e.target);
            var $component = $element.parents(".language-selector");
            var hadClass = $component.hasClass('open');
            $(".language-selector").removeClass('open');
            $(".language-modal-overlay").remove();
            if (!hadClass) {
                $component.addClass('open');
                $("body").append("<div class='language-modal-overlay'></div>");
            }
        })
        $("body").on('click touchstart', '.language-modal-overlay', function(e) {
            $(".language-selector").removeClass('open');
            $(".language-modal-overlay").remove();
        })
    })
});

document.addEventListener("DOMContentLoaded", function() {

	let secondaryNav = document.querySelector("header.header.w2-header .header.secondary-header");

	if (secondaryNav) {

		function apparelNav() {

			let primaryNavOffset = document.querySelector("header.header").getBoundingClientRect().height;
			let secondaryNavOffset = document.querySelector(".header.secondary-header").getBoundingClientRect().height;
			let mobileSecondaryNavOffset = document.querySelector(".hide-scroll").getBoundingClientRect().height;
			let container = document.querySelector('div.h-wrapper > section.viewport');
			let appNav = document.querySelector(".mobile-secondary-nav");
			let title;

			// Create nav title
			function sectionTitle() {
				const hide = (window.outerWidth <= 991) ? document.querySelector(".hide-scroll") : document.querySelector(".secondary-header .header__nav");
				title = (window.outerWidth <= 991) ? document.querySelectorAll(".mobile-secondary-nav .section-link")[0].innerHTML : document.querySelectorAll(".secondary-header .header-nav-link")[0].innerHTML;
				let node = document.createTextNode(title);
				const h4Container = document.createElement('h4');
				h4Container.classList.add("apparel-nav-section-title");
				hide.prepend(h4Container);
				h4Container.appendChild(node);

				if (window.outerWidth <= 991) {
					document.querySelectorAll(".mobile-secondary-nav .header-menu section")[0].remove();
				} else {
					document.querySelectorAll(".secondary-header .header__nav .header-menu")[0].remove();
				}

			}

			sectionTitle();

			// Offsets
			function offsetBody() {
				let offset = (window.outerWidth <= 991) ? primaryNavOffset + mobileSecondaryNavOffset : primaryNavOffset + secondaryNavOffset;
				container.style.marginTop = offset + 'px';

				if (window.outerWidth <= 991) {
					let menu = Array.from(appNav.children);
					let menuOffset = document.querySelector(".apparel-nav-section-title").offsetWidth;
					for (let i = 1; i < menu.length; i++) {
						menu[i].style.left = menu[0].offsetLeft + menuOffset + 'px';
					}
				}
			}

			offsetBody();

			function activeScroll() {
				let menuItems = (window.outerWidth <= 991) ? document.querySelectorAll(".hide-scroll a.section-link") : document.querySelectorAll(".secondary-header a.header-nav-link");
				let offset = (window.outerWidth <= 991) ? primaryNavOffset + mobileSecondaryNavOffset : primaryNavOffset + secondaryNavOffset;
				const scrollItems = [];

				for (let i = 0; i < menuItems.length; i++) {
					let menuItem = menuItems[i];
					let anchor = document.getElementById(menuItems[i].href.split("#").pop());
					let anchorOffset = anchor.getBoundingClientRect().top - offset;
					let scroll = {
						menuItem,
						anchor,
						anchorOffset
					}

					scrollItems.push(scroll);
				}

				document.body.addEventListener("scroll", scrollPage);
				window.addEventListener("scroll", scrollPage);

				const last = scrollItems[scrollItems.length - 1];

				function smoothScroll(target, targetPosition, duration) {
					let startPosition = (window.outerWidth <= 991) ? document.body.scrollTop : window.pageYOffset;
					let startTime = null;

					function loop(currentTime) {
						if (startTime === null) startTime = currentTime;
						const timeElapsed = currentTime - startTime;
						const run = ease(timeElapsed, startPosition, targetPosition, duration);

						document.body.scroll(0, run);
						window.scrollTo(0, run);

						if (timeElapsed < duration) requestAnimationFrame(loop);
					}

					function ease(t, b, c, d) {
						t /= d / 2;
						if (t < 1) return c / 2 * t * t + b;
						t--;
						return -c / 2 * (t * (t - 2) - 1) + b;
					}

					requestAnimationFrame(loop);
				}

				scrollItems.forEach(function(el) {
					el.menuItem.addEventListener("click", function(e) {
						e.preventDefault();
						let target = el.anchor;
						let targetPosition = target.getBoundingClientRect().top - (offset - 2);
						smoothScroll(target, targetPosition, 500);
					})
				})

				function scrollPage() {
					let deactivate = (window.outerWidth <= 991) ? Array.from(document.querySelectorAll(".hide-scroll a.section-link.active")) : Array.from(document.querySelectorAll(".secondary-header a.header-nav-link.active"));
					let currLink;
					let scrollBarPosition = window.pageYOffset | document.body.scrollTop;

					if (scrollBarPosition >= last.anchorOffset) {
						currLink = last.menuItem;
					} else {
						for (let i = 0; i < scrollItems.length; i++) {
							if (scrollBarPosition >= scrollItems[i].anchorOffset && scrollBarPosition < scrollItems[i + 1].anchorOffset) {
								currLink = scrollItems[i].menuItem;
							}
						}
					}

					if (currLink) {
						currLink.classList.add("active");
					}

					deactivate.forEach(function(el) {
						if (el !== currLink) {
							el.classList.remove("active");
						}
					})
				}

			}

			activeScroll();

		}
		apparelNav()
	}
});

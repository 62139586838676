document.addEventListener('DOMContentLoaded', function () {
    if ($('.email-capture').length > 0) {
        const blanket = document.querySelector('.blanket-overlay');

        function findRegion () {
            const region = document.getElementsByTagName('html')[0];
            const regionCode = region.getAttribute('lang').slice(-2);
            if (regionCode === 'EU' || regionCode === 'FR' || regionCode === 'DE' || regionCode === 'ES' || regionCode === 'IT') {
                $('.shopping-for').css('display', 'none');
            }
        }

        findRegion();

        $('.email-subscription-link').on("click", function () {
            if ($('.email-policy-description').hasClass('on')) {
                $('.email-policy-description').removeClass('on');
                $('.modal-overlay-policy').removeClass('policy--active');
            } else {
                $('.email-policy-description').addClass('on');
                $('.modal-overlay-policy').addClass('policy--active');
            }
        });

        $('.modal-overlay-policy').on("click", function () {
            $(this).removeClass('policy--active');
            $('.email-policy-description').removeClass('on');
        });

        const validateEmail = document.querySelector('.email-newsletter-subscribe__input');
        const validateTerms = document.querySelector('.hsco-opt-in-terms');
        const redError = document.querySelector(".email-newsletter-subscribe__policy");

        validateEmail.addEventListener('invalid', function (e) {
            e.preventDefault();
            $(".invalid-form-error-message").show();
            validateEmail.value = "";
            validateEmail.setAttribute("placeholder", "");
        }, true);

        validateEmail.onfocus = function () {
            if ($(".invalid-form-error-message").is(":visible")) {
                $(".invalid-form-error-message").hide();
                validateEmail.setAttribute("placeholder", "Email Address");
            }
        };

        validateTerms.addEventListener('invalid', function (e) {
            e.preventDefault();
            const termsCheck = document.querySelector(".email-newsletter-subscribe__policy");
            termsCheck.classList.add('red-error');
        }, true);

        redError.addEventListener('click', function() {
            if (this.classList.contains("red-error")) {
                this.classList.remove("red-error");
            } else {
                return;
            }
        });


        $('#hsco-opt-in').on('submit', function (e) {
            e.preventDefault();
            var $form = $(this);
            if ($('.hsco-opt-in-terms:checked').length > 0) {
                $form.hide();
                blanket.style.display = 'flex';
                HSC.Util.disableForm($form);
                let data = {};
                data.email = $form.find("[name=email-subscribe]").val();
                data.locale = $form.find("[name=locale]").val();
                if ($form.find("[name=source]")) {
                    data.source = $form.find("[name=source]").val();
                }
                if ($form.find("[name=gender]")) {
                    data.gender = $form.find("[name=gender]").val();
                }
                Newsletter.subscribe(data)
                    .then(function(response) {
                        blanket.style.display = 'none';
                        if (response.success) {
                            $('.before-submit').hide();
                            $('.after-submit').show();
                        } else {
                            toastr['info']('<div>We had problem adding your email.</div>');
                            return false;
                        }
                    });
            }
        });
    }
});

document.addEventListener('DOMContentLoaded', function () {
    const cardsSlider = document.querySelector('.component-card-list-variant-scroll .cards');

    function cardsDrag () {
		let isDown = false;
		let startX;
		let scrollLeft;
		var startTime, endTime;
		var longpress = false;

		cardsSlider.addEventListener('click', (e) => {
		    (longpress) ?  e.preventDefault() : "";
		});

        cardsSlider.addEventListener('mousedown', e => {
            isDown = true;
            startTime = new Date().getTime();
            cardsSlider.classList.add('active');
            startX = e.pageX - cardsSlider.offsetLeft;
            scrollLeft = cardsSlider.scrollLeft;
        });

        cardsSlider.addEventListener('mouseleave', () => {
            isDown = false;
            cardsSlider.classList.remove('active');
        });

        cardsSlider.addEventListener('mouseup', () => {
            endTime = new Date().getTime();
            if (endTime - startTime < 150) {
                longpress = false;
            } else if (endTime - startTime >= 200) {
                isDown = false;
                longpress = true;
                cardsSlider.classList.remove('active');
            }
        });

		cardsSlider.addEventListener('mousemove', (e) => {
            if(!isDown) return;
            e.preventDefault();
            const x = e.pageX - cardsSlider.offsetLeft;
            const walk = (x - startX) * 1; //scroll-fast
            cardsSlider.scrollLeft = scrollLeft - walk;
		});
    }

    if(cardsSlider) {
        cardsDrag();
    }

});
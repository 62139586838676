window.HSCO = window.HSCO || {};

/**
 * Handles converting prices to appropriate currency based on locale
 */
HSCO.Currency = (function(){

  /**
  * Sanitizes price
  */
  var getPrice = function(price) {
    price = price.toString(); // first convert to string for regex
    price = price.replace(/([,]{1}[0-9]{2}[\s$]{2})$/g, "");
    price = Number(price.replace(/[^0-9\.]+/g,"")); // convert back to number
    return price;
  }

  /**
  * Gets locale from hscoData
  */
  var getLocale = function() {
    var locale = hscoData.locale;
    if (locale == null || locale == "") {
      locale = "en-US";
    }
    return locale;
  }

  /**
  * Gets currency from hscoData
  */
  var getCurrency = function() {
    var currency = hscoData.currency;
    if (currency == null || currency == "") {
      currency = "USD";
    }
    return currency;
  }

  /**
  * Converts price to local currency
  */
  var convertPrice = function(price, locale, currency, type, decimals) {
    // See: https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
    var decimalsDefault = 2;
    if (decimals === undefined) {
      decimals = decimalsDefault;
    }
    return new Intl.NumberFormat(locale, { style: 'currency', currency: currency, minimumFractionDigits: decimals, maximumFractionDigits: decimals }).format(price);
  }

  return {
    getLocale: getLocale,
    getCurrency: getCurrency,
    /**
    * Sets the currency of the price amount
    * @param {String} price
    */
    setCurrency : function(price, type, decimals) {
      if (price === null || price === undefined) return null;
      var price = getPrice(price);
      var locale = getLocale();
      var currency = getCurrency();
      var convertedPrice;
      if (decimals !== undefined) {
        convertedPrice = convertPrice(price, locale, currency, type, decimals);
      }
      else {
        convertedPrice = convertPrice(price, locale, currency, type);
      }
      return convertedPrice;
    },

    updatePrice: function(element) {
      var price = $(element).html();
      var convertedPrice = this.setCurrency(price);
      $(element).html(convertedPrice);
    },

    convertToSmallestUnit: function(price) {
      var locale = getLocale();
      var convertedPrice;
      convertedPrice = Math.floor(Number(price) * 100);
      return convertedPrice;
    },

    setProductCards: function() {
      var prices = $('.hsco-set-currency');
      if(prices.length > 0) {
        for (i = 0; i < prices.length; i++) {
          HSCO.Currency.updatePrice(prices[i]);
        }
      }
    }
  }

})();

// On page load, find all prices with the class 'hsco-set-currency';
$(document).ready(function() {
    HSCO.Currency.setProductCards();
    Granite.I18n.setLocale(HSCO.Currency.getLocale());
});

document.addEventListener('DOMContentLoaded', function() {
    if (getUrlParameter('success')) {
        $("#hsco-contact-form").hide();
        $("#hsco-contact-form-success").show();
        setTimeout(function() {
            $(".h-cf-container")[0].scrollIntoView();
        }, 1000);
    }
    else if ($("#hsco-contact-form input[name='retURL']").length > 0) {
        const retUrl = $("#hsco-contact-form input[name='retURL']").val();
        const newRetUrl = retUrl.replace('www.herschel.com', window.location.hostname);
        $("#hsco-contact-form input[name='retURL']").val(newRetUrl);
    }
});
$(function(){
    $(document).ready(function() {
        $("body").on('click', '.has-languages', function(e) {
            var $element = $(e.target);
            var $component = $element.parents(".region-language-list");
            var hadClass = $component.hasClass('open');
            $(".region-language-list").removeClass('open');
            $(".region-modal-overlay").remove();
            if (!hadClass) {
                $component.addClass('open').find(".region-language-list-items").css('bottom', $component.outerHeight() + "px");
                $("body").append("<div class='region-modal-overlay'></div>");
            }
        })
        $("body").on('click', '.region-modal-overlay', function(e) {
            $(".region-language-list").removeClass('open');
            $(".region-modal-overlay").remove();
        })
    })
});

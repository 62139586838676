/**
 * Header Sizing for Mobile
 **/
$(function(){
    function onScroll(){
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
        if(top < 60){
            document.body.classList.add('h-scroll-top');
            document.body.classList.remove('h-scroll-not-top');
        } else {
            document.body.classList.add('h-scroll-not-top');
            document.body.classList.remove('h-scroll-top');
        }
    }

    var timeout = null;
    window.addEventListener("scroll", function(){
        if(timeout){
            clearTimeout(timeout);
            timeout = null;
        }
        setTimeout(onScroll, 100);
    });

    $(document).ready(function() {
        $(".header--has-caret").on("click", function(){
            $(this).nextAll("ul").fadeToggle();
        });

        $(".js-nav-slider").slick({
            speed: 300,
            slidesToShow: 1.5,
            slidesToScroll: 1,
            infinite: false,
            arrows: false
        });

        // open and close main nav mobile
        $(".header__hamburger").on('touchstart click', function() {
          var delay = 0;
          var $listSizeItem = $('.h-wrapper').find('.modal__mobile_container').find("section");

          // open
          if (!$(this).hasClass('header__menu-link--active')) {
            setTimeout(function() {
              $($listSizeItem).each(function() {
                var $item = $(this);
                setTimeout(function() {
                  $(window).trigger('resize');
                  $item.addClass('nav-link--onscreen');
                }, delay+=100); // delay 100 ms
              });
            }, 600);
          }

          // close
          else {
            setTimeout(function() {
              $($listSizeItem).each(function() {
                var $item = $(this);
                setTimeout(function() {
                  $item.removeClass('nav-link--onscreen');
                }, delay+=100); // delay 100 ms
              });
            }, 600);
          }
        });


        // open the mobile submenu when clicking on the mobile menu link
        $(".modal[data-modal='mobile'] a.section-link").on('click', function(e) {
            var $modal = $(".modal[data-modal='mobile']");
            var $this = $(this);
            var $section = $this.parent();
            var $list = $section.find("ul");

            // Redirect if there is no submenu items
            if($list.length > 0){
                e.preventDefault();
                var $listSizeItem = $section.find("ul").find('*');
                var time = 0;
                var verticalPosition = $this.position().top + $(".modal__mobile_container").scrollTop();
                // UP - move section up if needed
                if (!$section.hasClass('showing')) {
                    var originalVertPos = verticalPosition;
                    // hides other sections - keeps position
                    $modal.find("section").not($section).find('a').addClass('display--none');
                    $modal.find("ul").hide();
                    $modal.find('.back-button-container').show();
                    $section.addClass('showing').show();
                    $list.show();

                    // $($this).animate({marginTop: -originalVertPos});
                    $(".modal__mobile_container").animate({
                            scrollTop: 0,
                        },
                        {
                            queue: false,
                            duration: 333
                        });
                    $($this).animate({
                        marginTop: -originalVertPos,
                    }, {
                        queue: false,
                        duration: 333,
                        complete: function() {
                            var delay = 0;
                            $($listSizeItem ).each(function() {
                                var $li = $(this);
                                setTimeout(function() {
                                    $li.addClass('subnav-link--onscreen');
                                }, delay+=60); // delay 100 ms
                            });
                        }
                    });
                }

                // DOWN - move section down if needed
                else {
                    closeSubNav();
                }
            }
        });

        // Hello elva navigations
        $(".modal[data-modal='mobile'] a.new-section-link").on('click', function(event) {
            // display the navigation here
            event.preventDefault();
            var $modal = $(".modal[data-modal='mobile']");
            var $this = $(this);
            var $section = $this.parent();
            var dataSetId = this.dataset.modelId;
            var $navMenu = $('.' + dataSetId);
            var time = 0;
            var verticalPosition = $this.position().top + $(".modal__mobile_container").scrollTop();
            // UP - move section up if needed
            if (!$section.hasClass('showing')) {
                var originalVertPos = verticalPosition;
                // hides other sections - keeps position
                $modal.find("section").not($section).find('a').addClass('display--none');
                $modal.find("ul").hide();
                $modal.find('.back-button-container').show();
                $section.addClass('showing').show();

                // $($this).animate({marginTop: -originalVertPos});
                $(".modal__mobile_container").animate({
                        scrollTop: 0,
                    },
                    {
                        queue: false,
                        duration: 333
                    });
                $($this).animate({
                    marginTop: -originalVertPos,
                }, {
                    queue: false,
                    duration: 333,
                    complete: function() {
                        var delay = 0;
                        setTimeout(function() {
                            $navMenu.removeClass('hidden');
                            // Since the slider is hidden on load.
                            // Needs to be refreshed to toggle size on menu click
                            $('.js-nav-slider').slick('refresh');
                        }, delay+=60);
                    }
                });
            }
            else {
                closeSubNav(dataSetId);
            }

        });

        // go back to the main mobile menu when clicking the back button
        $(".modal[data-modal='mobile'] .back-button-container").on('click', function() {
          closeSubNav();
        });


        function closeSubNav(dataSetId){
          var $modal = $(".modal[data-modal='mobile']");
          var $section = $('section.showing')
          var $sectionLink = $('section.showing a')
          var $sectionChildren = $('section.showing').find('ul').children('li');
          var elvaSections =  $('.menu-section-container');
          var reversedElements = $($sectionChildren).get().reverse();
          var MAX_DELAY = 1000;
          var MAX_ITEMS = 8;
          var ANIMATION_LENGTH = 250;
          var delayPerItem = (reversedElements.length < MAX_ITEMS) ? 100 : Math.round(MAX_DELAY/reversedElements.length) ;
          var delay = (reversedElements.length < MAX_ITEMS) ? reversedElements.length * delayPerItem : MAX_DELAY ;


          // hide children li
          $(reversedElements).each(function(idx) {
            var $li = $(this);
            setTimeout(function() {
              $li.removeClass('subnav-link--onscreen');
            }, delayPerItem * (idx+1)); // delay 100 ms
          });

          // Hide all the elva containers
          $(elvaSections).each(function(index, element){
             $(element).addClass('hidden');
          });

          setTimeout(function() {
            $($sectionLink).animate({marginTop: 0});
          }, delay + ANIMATION_LENGTH)

          // hide ul and back buttons
          setTimeout(function() {
            $modal.find("ul").hide();
            // $modal.find("section").show();
            $modal.find('.back-button-container').hide();
            $section.removeClass('showing');
            $modal.find("section").not($section).find('a').removeClass('display--none');
            if(dataSetId){
                $('.'+ dataSetId).addClass('hidden');
            }
          }, delay + ANIMATION_LENGTH * 2);
        }



        var modalLink;
        var modal;
        // desktop tablet tap dropdown open/link open
        var headerNavLinkTracker = null;
        // prevent click events caused by touches on the header links
        // browsers have an inbuilt 300ms window where the emulated
        // click event will be fired after a touch.
        $('body').on('touchend', function(e) {
          $('body').on('click.header-nav-link', '.header-nav-link', function(e) {
            e.preventDefault();
            e.stopPropagation();
            return false;
          });
          setTimeout(function() {
            $('body').off('click.header-nav-link');
          }, 400);
        });
        $('body').on('touchstart', '.header-nav-link', function(e) {
          e.preventDefault();
          e.stopPropagation();
          headerNavLinkTracker = e.currentTarget;
          $('body').one('touchend', function(e) {
            if (headerNavLinkTracker == e.target) {
              e.preventDefault();
              e.stopPropagation();
              var tmpModalLink = getModalLink($(e.target));
              var tmpModal = hasModal(tmpModalLink.attr('data-modal'));
              if (tmpModal) {
                if (!tmpModal.hasClass('modal--active')) {
                  dropdownOpen(e);
                } else {
                  openLink(e.target);
                }
              }
              else {
                openLink(e.target);
              }
              return false;
            }
            return false;
          })
        })
        function openLink(htmlElement) {
          if ($(htmlElement).attr('href')) {
            window.location.href = $(htmlElement).attr('href');
          }
        }
        function getModalLink($this) {
          return $this.parent().find('a.open-header-nav-modal');
        }
        function hasModal(id) {
          var tmpModal = $(".modal[data-modal='"+id+"']");
          if (tmpModal.length == 0) return false;
          return tmpModal;
        }
        // desktop hover dropdown open
        function dropdownOpen(e) {
          var $this = $(e.target);
          var tmpModalLink = getModalLink($this);
          var tmpModal = hasModal(tmpModalLink.attr('data-modal'));
          // make the modal disappear for links that have no content
          if (!tmpModal) {
              $("header.w2-header .header-nav-link").removeClass('active');
              if (modal) {
                modal.removeClass('modal--opaque');
                setTimeout(modalLink.click.bind(modalLink), 300);
              }
              modalLink = null;
              modal = null;
              return;
          }
          if (modal && tmpModalLink.attr('data-modal') != modalLink.attr('data-modal')) {
            var oldModal = modal;
            oldModal.removeClass('modal--opaque');
          }
          modalLink = tmpModalLink;
          modal = tmpModal;
          if (!modal.hasClass('modal--active')) {
              modalLink.trigger("click");
              $("header.w2-header .header-nav-link").removeClass('active');
              $this.addClass('active');
              if (modal.hasClass('header-nav-modal')) {
                  modal.css("left", (($(window).width() - $(modal).outerWidth()) / 2) + "px");
              }
              setTimeout(function() {tmpModal.addClass('modal--opaque');});
          }
        }
        $('body').on('mouseenter', '.header-nav-link', dropdownOpen)
        // desktop hover dropdown close
        .on('mouseenter touchstart', 'section.viewport', function(e){
            if (modal && modal.hasClass('modal--active')) {
                $("header.w2-header .header-nav-link").removeClass('active');
                modal.removeClass('modal--opaque');
                setTimeout(function() {
                  if (modalLink.hasClass('h-modal-link--active')) {
                    modalLink.trigger("click");
                  }
                }, 300);
            }
        });
        Commerce.getUser().then(function(user) {
            if (user && user.data && user.data.role == "REGISTERED") {
                $(".sign-in-listener").addClass("signed-in");
            }
        })
    })
}());

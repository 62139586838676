$(function() {

    // Event Binder to add to card
    $('.upsell-js-add-cart').on("click", function(event){
      let $source;
      let eventSource = event.currentTarget.dataset.source;
      if(eventSource == 'discount-bundle'){
          $source = $('.discount-product-section');
      }else{
          $source = $(".hsco-cart");
      }

      $source.LoadingOverlay('show', {
        zIndex: 2147483641
      });
      addToCart(event, $source);
    });
    /**
     * Add to cart a given sku and refresh the cart to show changes.
     * */
    function addToCart(event, $source) {
        if (!event.currentTarget.dataset) {
            return;
        }

        var skus = [];
        var hasProductAndVariantId = event.currentTarget.dataset.productId && event.currentTarget.dataset.variantId;
        var hasSku = event.currentTarget.dataset.sku;
        var canAddToCart = Commerce.prioritizeProductId ? hasProductAndVariantId : hasSku;

        if (canAddToCart) {
            skus.push(event.currentTarget.dataset.sku);
            Commerce.getPDPPrices(skus, event.currentTarget.dataset.productId, event.currentTarget.dataset.variantId).then(function (data) {
                // Call add to cart for adding to cart
                $source.LoadingOverlay('hide');
                if(data && data[0]){
                    var product = data[0];
                    Commerce.addToCart(product, 1)
                        .then(function() {
                            let addToCartData = {};
                            addToCartData.itemName = event.currentTarget.dataset.displayname;
                            addToCartData.itemId = product.sku;
                            addToCartData.itemVariant = event.currentTarget.dataset.color;
                            addToCartData.listPrice = product.listPrice;
                            addToCartData.price = product.purchasePrice;
                            addToCartData.discount = product.listPrice - product.purchasePrice;
                            addToCartData.quantity = 1;
                            addToCartData.shopifyProductId = product.shopifyProductId;
                            addToCartData.shopifyVariantId = product.shopifyVariantId;

                            HSCO.DataLayer.setAddToCart(addToCartData);
                        })
                        .then(AEM.CT.refreshCart,(error)=>{
                            toastr.error(Granite.I18n.get('There was a problem adding product to your cart.'));
                            $source.LoadingOverlay('hide');
                        }).then(AEM.CT.updateCartCount)
                        .then(function () {
                            $source.LoadingOverlay('hide');
                            // close modal
                        }, function (err) {
                            $source.LoadingOverlay('hide');
                            toastr.error(Granite.I18n.get('There was a problem adding to your cart.'));
                        });
                }else{
                    toastr.error(Granite.I18n.get('There was a problem adding product to your cart.'));
                    $source.LoadingOverlay('hide');
                }
            },function(){
                toastr.error(Granite.I18n.get('There was a problem adding product to your cart.'));
                $source.LoadingOverlay('hide');
            })
        }
    }
});


(function () {
    var template = _.template($("#hsco-site-search-template").html());

    var $container = $(".js-search-results-template-placeholder");
    var $button = $container.parent().find("button");
    var $input = $("#js-site-search-query-input");
    var loadingValue = "";
    var modalCanScroll = _.debounce(function(){
        var mWindowHeight = $(window).height();
        var searchResultsDiv = $('.js-search-results-template-placeholder').height() + 200;
        if(mWindowHeight < searchResultsDiv){
          $('.modal--expanding.modal--active').css({'bottom': 0});
        }else {
          $('.modal--expanding.modal--active').css({'bottom': 'auto'});
        }
    }, 100);
    var loadResults = _.debounce(function(){
        loadingValue = $input.val();
        if ($input.val().length >= 2) {
            var $form = $input.closest("form");
            $.ajax({
                "url": "/bin/herschel/product/search.json",
                "method": "GET",
                "dataType": "json",
                "data": $form.serializeArray(),
                "traditional": true
            }).done(function (data) {
                // Loop through results to set currency on each item with a price
                var formAction = "/search";
                _.each(data.results, function (result, prop) {
                    localStorage.setItem('algoliaQueryID', result.algoliaQueryID);
                    var algoliaObjectPaths = [];
                    if (result.type == 'redirect') {
                        formAction = result.url;
                    } else {
                        _.each(result.products, function (product, prop) {
                            if (product.price) {
                                // If product contains "from"
                                if (product.price.includes("from")) {
                                    product.price = product.price.replace(/[^0-9\.]+/g, "");
                                    product.price = HSCO.Currency.setCurrency(product.price);
                                    product.price = "from " + product.price;
                                } else {
                                    product.price = HSCO.Currency.setCurrency(product.price);
                                }
                            }
                            if (product.path) {
                                algoliaObjectPaths.push(product.path.substring(0, product.path.indexOf("?")));
                            }
                        })
                    }
                    localStorage.setItem('algoliaObjectPaths', JSON.stringify(algoliaObjectPaths));
                });
                if (loadingValue == $input.val()) {
                    var content = template(data);
                    $container.empty();
                    $container.append(content).ready().find('img').one('load', modalCanScroll);
                    if (data.results.length) {
                        $form[0].action = formAction;
                        $button.show();
                    }
                    else {
                        $button.hide();
                    }
                }

            }).fail(function () {
                if (loadingValue == $input.val()) {
                    $container.empty();
                    $button.hide();
                }
            }).always(function(){
                setTimeout(modalCanScroll, 40);
            });
        }
        else {
            $container.empty();
        }
    }, 300);
    $("#js-site-search-query-input").on('keyup', function () {
        loadResults();
    })
    $("#js-site-search-query-input").on("keyup change click", function(e) {
        modalCanScroll();
    });
  
    window.addEventListener('resize', modalCanScroll);
    $container.on("click", ".js-search-accordion", function(e){
        e.preventDefault();
        $(this).find('.header--has-caret').toggleClass('caret-flipped');
        $(this).closest(".row").next().toggle();
    });

}());

window.AEM = window.AEM || {}

AEM.CT = (function() {
  var $cartCount = $("a[data-modal='cart'] sup, .js-cart-count");
  var $subTotal = $(".hsco-cart-modal-subtotal");
  var $discount = $(".hsco-order-discount");
  var $total = $(".hsco-cart-modal-total");
  var $hscoCart = $(".hsco-cart");
  var $gwpTitle = $(".gwp");
  var $gwpMessage = $("#js-gwp-message");

  var showCartOverlay = function() {
    $hscoCart.LoadingOverlay('show', {
      zIndex: 2147483641
    });
  }
  var debouncers = {};
  $(document).on("click", ".hsco-checkout-button", function(e) {
    e.preventDefault();
    var $this = $(this);
    var type = $this.data('type');

    if (!type) {
      console.log("This checkout button requires a data-type attribute and an associated element to open when clicked.");
      return false;
    }
    if (type === '.login-checkout' && Commerce.isCustomerRegistered()) {
      document.location.assign(Commerce.checkoutUrl);
      return false;
    }
    if (type === '.guest-checkout' && !Commerce.isHeadlessCheckout && Commerce.checkoutUrl) {
      document.location.assign(Commerce.checkoutUrl);
      return false;
    }
    $('.hsco-cart .cart-checkout-step').hide();
    $('.hsco-cart .cart-checkout-curtain').show();
    $('.hsco-cart ' + type).show();
    return false;
  })
  $(document).on("keypress", ".hsco-cart-quantity-selector", function(e) {
    if (e.keyCode === 13) {
      quantityChange(e);
      var id = $(e.target).prop('name');
      var $input = $hscoCart.find("input[name=" + id + "]");
      $input.attr('data-last-enter', $input.val());
    }
  });
  $(document).on("change", ".hsco-cart-quantity-selector", function(e) {
    var id = $(e.target).prop('name');
    var $input = $hscoCart.find("input[name=" + id + "]");
    if ($input.val() !== $input.attr('data-last-enter')) {
      quantityChange(e);
    }
    $input.removeAttr('data-last-enter');
  });
  function quantityChange(e) {
    var id = $(e.target).prop('name');
    var $input = $hscoCart.find("input[name=" + id + "]");
    if ($input.data("gwp") === true) {
      $input.val(1); // maintain a value of 1
      return false; // don't allow an update to the qty of GWPs
    }
    if (!debouncers.hasOwnProperty(id)) {
      var debounced = _.debounce(function(id) {
        AEM.CT.updateQuantity(id);
      }, 1000);
      debouncers[id] = debounced;
      debounced(id);
    } else {
      debouncers[id](id);
    }
  }
  function setAddToCartEvent(e) {
    let addToCartData = {};
    let rowModel = $(e.target).closest(".hsco-row-model");

    if (rowModel) {
        addToCartData.itemName = rowModel.data("displayname");
        addToCartData.itemId = rowModel.data("sku");
        addToCartData.itemVariant = rowModel.data("colorname");
        addToCartData.listPrice = rowModel.data("listprice");
        addToCartData.price = rowModel.data("purchaseprice");
        addToCartData.discount = addToCartData.listPrice - addToCartData.price;
        addToCartData.quantity = rowModel.data("quantity");
        addToCartData.shopifyProductId = rowModel.data("productid");
        addToCartData.shopifyVariantId = rowModel.data("variantid");
    }

    HSCO.DataLayer.setAddToCart(addToCartData);
  }
  function setRemoveFromCartEvent(e) {
    let removeFromCartData = {};
    let rowModel = $(e.target).closest(".hsco-row-model");

    if (rowModel) {
        removeFromCartData.itemName = rowModel.data("displayname");
        removeFromCartData.itemId = rowModel.data("sku");
        removeFromCartData.itemVariant = rowModel.data("colorname");
        removeFromCartData.listPrice = rowModel.data("listprice");
        removeFromCartData.price = rowModel.data("purchaseprice");
        removeFromCartData.discount = removeFromCartData.listPrice - removeFromCartData.price;
        removeFromCartData.quantity = rowModel.data("quantity");
        removeFromCartData.shopifyProductId = rowModel.data("productid");
        removeFromCartData.shopifyVariantId = rowModel.data("variantid");
    }

    HSCO.DataLayer.setRemoveFromCart(removeFromCartData);
  }

  $(document).on("click", ".hsco-delete-item", function(e) {
    e.preventDefault();
    showCartOverlay();

    let rowModel = $(e.target).closest(".hsco-row-model");

    if (rowModel) {
      let url = rowModel.data("carturl");
      let id = rowModel.data("id");
      AEM.CT.deleteItem(url, id);
      setRemoveFromCartEvent(e);
    }
  });

  $(document).on("click", ".hsco-cart-item-more, .hsco-cart-item-less", function(e) {
    e.preventDefault();
    var $increase = $(this);
    var $input = $increase.parent().find("input");
    if ($increase.hasClass("hsco-cart-item-more")) {
      $input.val(Number($input.val()) + 1);
      setAddToCartEvent(e);
    } else if (Number($input.val()) > 1) {
      $input.val(Number($input.val()) - 1);
      setRemoveFromCartEvent(e);
    }
    $input.trigger('change');

  });

  var removeLoadingOverlay = function() {
    $hscoCart.LoadingOverlay('hide', true);
  };

  var user = {};
  Commerce.getUser().then(function(USER) {
    user = USER;
  });
  var commandQueue = [];
  var commandQueueRunning = false;
  var commandQueueRun = function() {
    // base case--if we've used up all the command queue and are run
    // then we should refresh the cart
    if (commandQueue.length == 0) {
      commandQueueRunning = true;

      return AEM.CT.refreshCart()
        .always(removeLoadingOverlay)
        .always(function() {
          commandQueueRunning = false;
          // if the queue is non-empty when we're done, go again
          if (commandQueue.length > 0) {
            commandQueueRun();
          }
        });
    }

    if (commandQueueRunning) {
      return;
    }

    commandQueue[0]().always(function() {
      commandQueue.shift();
      commandQueueRunning = false;
      commandQueueRun();
    })
  }
  return {
    updateCartCount: function() {
      var $cartCount =  $("a[data-modal='cart'] sup, .js-cart-count");

      if ($cartCount.text()=='1') {
        toastr['info'](Granite.I18n.get('{0} item in Cart', [$cartCount.html()], 'pdp > quantity of items added to cart message'), undefined, {timeOut: 5000});
      }
      else {
        toastr['info'](Granite.I18n.get('{0} items in Cart', [$cartCount.html()], 'pdp > quantity of items added to cart message, plural'), undefined, {timeOut: 5000});
      }
    },
    refreshCart (checkToken) {
      // Only show overlay loader when not on reset password page
      if (!$('#hsco-reset-password').hasClass('create-new-password')) {
        showCartOverlay();
      }
      return Commerce.getCart(checkToken).then(function(data) {
        var _templateSidebar = _.template($("#hsco-cart-item-template").html());
        // if we are showing the full cart page
        if ($("#hsco-cart-page-template").length > 0) {
          var _templatePage = _.template($("#hsco-cart-page-template").html());
        }

        $cartCount.html(data.quantity == 0 ? "" : data.quantity);
        $subTotal.html(data.subtotal);

        if (data.discount > 0) {
          $discount.html(HSCO.Currency.setCurrency(data.discount));
          $discount.parent().show();
        } else {
          $discount.parent().hide();
        }

        //$total.html(HSCO.Currency.setCurrency(data.totalPrice - data.discount));

        // Update GWP layer html
        var gwpSku = hscoData.gwpSku;
        if (gwpSku && (gwpSku != undefined) && (gwpSku != "")) {
          var hasGwpItem = false;
          _.each(data.products, function (product) {
            if (product.sku == gwpSku) {
              hasGwpItem = true;
              var listPrice = product.listPrice;
              var purchasePrice = product.purchasePrice;
              if (listPrice == purchasePrice) {
                $gwpMessage.html("Get your " + hscoData.gwpName + " for free with orders " + hscoData.gwpOrderAmount);
                $gwpTitle.removeClass("hidden");
                $gwpMessage.removeClass("hidden");
              }
            }
          });
          if (!hasGwpItem) {
            $gwpMessage.html("Receive a complimentary " + hscoData.gwpName + " with orders " + hscoData.gwpOrderAmount);
            $gwpTitle.removeClass("hidden");
            $gwpMessage.removeClass("hidden");
          }
        }

        if (data.quantity > 0) {
          $cartCount.addClass('active');

          for (i = 0; i < data.products.length; i++) {
            data.products[i].subtotal = data.products[i].subtotal;
          }

          $(".hsco-cart-empty").hide();
          $("#hsco-cart-subtotal,.hsco-order-summary").show();

          var html = _templateSidebar(data);
          //html = $(html);
          $(".hsco-cart-item-view") // cart within header sidebar
            .empty()
            .html(html)
            .find("a[data-gwp='true']").removeAttr('href'); //@todo need better criteria

          // if we are showing the full cart page
          if ($("#hsco-cart-page-template").length > 0) {
            var html = _templatePage(data);
            $(".hsco-cart-page-view") // cart page
              .empty()
              .html(html)
              .find("a[data-gwp='true']").removeAttr('href'); //@todo need better criteria
          }

          $(".hsco-checkout-button").show();
        } else {
          $cartCount.removeClass('active');
          $(".hsco-cart-empty, .hsco-cart-no-items").show();
          $(".hsco-cart-full").hide();
          $("#hsco-cart-subtotal,.hsco-order-summary, .hsco-cart-has-items").hide();
          $(".hsco-cart-item-view").empty(); // cart within header sidebar

          // if we are showing the full cart page
          if ($("#hsco-cart-page-template").length > 0) {
            $(".hsco-cart-page-view").empty(); // cart page
          }

          $(".hsco-checkout-button").hide();
        }
        //Update data layer, google_tag_params
        HSCO.DataLayer.setCartProducts(data);

      }).always(removeLoadingOverlay);
    },
    updateQuantity: function(name) {
      var $input = $hscoCart.find("input[name=" + name + "]");
      if ($input.data("gwp") === true) return false; // don't allow an update to the qty of GWPs
      commandQueue.push(AEM.CT._updateQuantity.bind(this, name, $input.val()));
      commandQueueRun();
    },
    _updateQuantity: function(name, qty) {
      var $input = $hscoCart.find("input[name=" + name + "]");
      var cartitem = $input.closest(".hsco-row-model");
      var carturl = cartitem.data("carturl");
      var id = cartitem.data("id");

      showCartOverlay();
      return Commerce.updateCartQuantity(carturl, id, qty, name)
        .then(
          function(data) {
            return data
          }, function(error) {
            toastr.info(Granite.I18n.get(error.responseText));
          }
        );
    },
    deleteItem: function(url, id) {
      commandQueue.push(AEM.CT._deleteItem.bind(this, url, id));
      commandQueueRun();
    },
    _deleteItem: function(url, id) {
      showCartOverlay();
      return Commerce.removeFromCart(url, id);
    },
    getQueryString: function(field, url) {
      var href = url ? url : window.location.href;
      var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
      var string = reg.exec(href);
      return string ? string[1] : null;
    }
  }
})();

$(function() {
  Commerce.getUser().then(function() {
    AEM.CT.refreshCart(true);
  });
});

$(function(){
  // open the category questions
  $(".faq-category").on("click", function(e) {
      e.preventDefault();
      $(this).toggleClass("open");
      $(this).nextAll(".faq").slideToggle(200);
  });

  // open the answer
  $(".faq__question").on("click", function(e) {
      e.preventDefault();
      $(this).toggleClass("link--underline");
      $(this).parent().toggleClass("open");
      $(this).next(".faq__answer").slideToggle(200);
  });
});

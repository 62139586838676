HSC = window.HSC || {};

HSC.Util = {

	disableForm : function($form){
		var $button = $form.find("button");
		$button.prop("disabled", true);
		$button.data("original-html", $button.html());
		$button.html('<i class="btb bt-sm bt-spin bt-spinner"></i>');
	},

	enableForm : function($form, reset){
		if (reset){
			$form[0].reset();
		}
		var $button = $form.find("button");
		$button.html($button.data("originalHtml"));
		$button.prop("disabled", false);

	},

	getStoredImages : function(){
		return JSON.parse(localStorage.getItem("images") || "{}");
	},

	setStoredImages : function(json){
		if (json.date)
			json.date = Date.now();
		localStorage.setItem("images", JSON.stringify(json));
	},

	getProductFromJcr : function(sku) {
		var deferred = $.Deferred();
		$.get('/bin/herschel/product/sku.'+sku+'.json').done(function(result) {
			deferred.resolve(result);
		})
		.fail(function() {
			deferred.resolve({
				ecom_name: "SKU: " + sku,
				ecom_colour_description: "",
				ecomm_size: "One Size",
				ecomm_product_category: ""
			});
		});
		return deferred.promise();
	}
};

$(document).on('click', function(event) {
	if (event.target.className == 'bfx-cc-expanded') {
		$(".bfx-cc-menu").trigger("click");
	}

});

//TODO: remove this file after all legacy product grids deleted
var Product2018SlickSettings = {
    infinite: false,
    touchMove: true,
    slidesToShow: 9,
    slidesToScroll: 9,
    speed: 300,
    dots: false
};
window.ProductCardInitialize = function() {
    $('.product-2018-container').not('.disable-interactions').each(function() {
        if ($(this).find('.js-slick-swatches').hasClass('slick-initialized')) return;
        $(this).find('.js-slick-swatches').each(function() {
            if ($(this).find('.slide').length <= Product2018SlickSettings.slidesToShow) {
                $(this).addClass('no-arrows');
            }
            var settings = Object.assign({appendArrows: $(this).parent()}, Product2018SlickSettings);
            $(this).slick(settings);
        });

        $(this).find(".product-image").on("mouseenter", function() {
            $(this).find('.hover-tools').show().find('.slick-slider').slick('setPosition');
        }).on("mouseleave", function() {
            $(this).find('.hover-tools').hide();
        });
        var $product = $(this);
        if ($(this).find('.js-slick-swatches').length > 0) {
            var hammertime = new Hammer.Manager($(this).find(".product-image")[0]);
            hammertime.add( new Hammer.Swipe({ direction: Hammer.DIRECTION_HORIZONTAL, threshold: 50 }) );
            hammertime.on('swipe', function(ev) {
                var $slide;
                if (ev.direction == 2) {
                    $slide = $product.find('>.swatches .js-slick-swatches .swatch.active').parent().next();
                }
                else if (ev.direction == 4) {
                    $slide = $product.find('>.swatches .js-slick-swatches .swatch.active').parent().prev();
                }
                if ($slide && $slide.length) {
                    $product.find('>.swatches .js-slick-swatches').slick('slickGoTo', $slide.data('slickIndex'), false);
                    $slide.trigger("click");
                }
            });
        }
    });
}
$(document).ready(function() {
    window.ProductCardInitialize();
    $(document).on('click', '.product-2018-container .slide', function() {
        var $this = $(this).find('.swatch');
        var $card = $this.parents('.product-2018-container');
        var $link = $card.find('a.js-product-grid-link');
        var $image = $card.find('.product__image');
        var $color = $card.find('.color-template');
        var useExact = $link.data('useExact');
        $color.text($this.data('color'));
        $image.one('load', function() {
            $image.LoadingOverlay("hide");
        })
        $image.LoadingOverlay("show", {zIndex: 99});
        $image.attr('src', $this.data('productImage') + '.sthumbnails.680.850.webp');
        $image.attr('srcset', $this.data('productImage')+ ".sthumbnails.680.850.webp 680w, "+$this.data('productImage')+".sthumbnails.300.375.webp 330w, "+$this.data('productImage')+".sthumbnails.1000.1250.webp 1000w, "+$this.data('productImage')+".sthumbnails.1000.1250.webp 2500w");
        if (useExact) {
            $link.attr('href', $link.data('href') + '?v=' + $this.data('sku'));
        }
        else {
            $link.attr('href', $link.data('href') + '?v=' + $this.data('sku') + '&exact=1');
        }
        $link.attr('data-sku', $this.data('sku'));
        $card.find('.swatch.active').removeClass('active');
        $this.addClass('active');
    })

    // This is the JS hack to squash the duplicate price bug - CMS-1193. This is a temporary fix that will be revisted later.
    // @todo if we're going to keep this we should use class names instead of this weird tree-traversal code...
    // this code was nearly impossible to find and absolutely incomprehensible before i walked it through a debugger :(
    var item = Array.from(document.querySelectorAll(".product-2018-container"));

    item.forEach(function(elem, i) {

        if (item[i].children[1].children[1].childElementCount === 3) {
            var onSale = elem.children[1].children[1].children[1];
            var salePrice = elem.children[1].children[1].children[1].innerText;
            var regPrice = elem.children[1].children[1].children[2].innerText;
        } else if (item[i].children[1].children[1].childElementCount === 2) {
            var onSale = elem.children[1].children[1].children[0];
            var salePrice = elem.children[1].children[1].children[0].innerText;
            var regPrice = elem.children[1].children[1].children[1].innerText;
        } else {
            salePrice = null;
        }

        if (salePrice === regPrice) {
            onSale.style.display = "none";
        }
    });


    var productCard = Array.from(document.querySelectorAll(".product-card.text-grey4.link-hover--grey-dark"));

    productCard.forEach(function(e, i) {
        if (productCard[i].children[0].children[0].children[0].children[0].children[1].childElementCount === 4) {
            var onSalePC = e.children[0].children[0].children[0].children[0].children[1].children[1];
            var salePricePC = e.children[0].children[0].children[0].children[0].children[1].children[1].children[0].innerText;
            var regPricePC = e.children[0].children[0].children[0].children[0].children[1].children[2].innerText;
        } else if (productCard[i].children[0].children[0].children[0].children[0].children[1].childElementCount === 3 && productCard[i].children[0].children[0].children[0].children[0].children[1].children[0].innerText != "from ") {
            var onSalePC = e.children[0].children[0].children[0].children[0].children[1].children[0];
            var salePricePC = e.children[0].children[0].children[0].children[0].children[1].children[0].children[0].innerText;
            var regPricePC = e.children[0].children[0].children[0].children[0].children[1].children[1].innerText;
        } else {
            salePricePC = null;
        }

        if (salePricePC === regPricePC) {
            onSalePC.style.display = "none";
        }
    });
});

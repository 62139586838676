window.HSCO = window.HSCO || {};

HSCO.Skinny = (function () {

  let SKINNY_COOKIE_NAME = 'skinnyCookie';
  var $banner = $('.skinny-banner-container');

  let SINGLE_SINGLE_SETTINGS = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000
  };

  $('.capsule-skinny-carousel').slick(SINGLE_SINGLE_SETTINGS);

  var enableCapsuleBanner = function(){
    // close the container
    $('.capsule-skinny-banner-container').removeClass('close')
        .addClass('open');
    $('.capsule-skinny-carousel').slick('refresh');
  }

  $('.close-capsule-skinny').on("click", function(event){
    // Set the cookie for 24 hours
    setCapsuleBannerCookie(SKINNY_COOKIE_NAME, '');
    // close the container
    $('.capsule-skinny-banner-container').removeClass('open')
                                         .addClass('close');
  });

  var setCapsuleBannerCookie = function(name,value,days=1){
    if(days){
      var d=new Date();
      d.setTime(d.getTime()+(days*24*60*60*1000));
      var expires = "; expires="+d.toGMTString();
    }
    else var expires = "";
    document.cookie = name+"="+value+expires+"; path=/";
  }

  var skinnyBannerCookieExists = function() {
    // We are gonna use the same cookie used by the skinny banner so that both
    // can be used without creating too many cookies.
    var keyValue = document.cookie.match('(^|;) ?' + SKINNY_COOKIE_NAME + '=([^;]*)(;|$)');
    if(keyValue){
      return true;
    }
    return false;
  }

  var closeSkinnyBanner = function (immediate) {
    $('.skinny-banner-container').removeClass('open');
    $('body').removeClass('skinny-banner-open');

    setTimeout(function () {
      $('.skinny-banner-container').remove();
      $('body').trigger('skinny-banner.closed');
    }, 1000);
  }

  var isCartPage = function(){
    if(window.hscoData.pageType === "cart"){
      return true;
    }
    return false;
  }

  $(document).ready(function() {
    if(!skinnyBannerCookieExists() && !isCartPage()){
      enableCapsuleBanner();
    }
  });

  $(document).on("click", '.close-skinny', function (e) {  

    var bannerId = $banner.data('bannerId');
    Cookies.set('skinnyCookie', bannerId);
    closeSkinnyBanner();
    repositionNewSearchBar(skinnyBannerCookieExists(), skinnyBannerEnabled );
    updatePlpFilter();

  });

  var updatePlpFilter = () => {
    $('#grid-features-filter').addClass('skinny-banner-closed');
    var newHeight = $('.card-grid-subnav .subnav-dropdown').outerHeight() + $('.skinny-banner-container').outerHeight();
    $('.card-grid-subnav .subnav-dropdown').height(newHeight);
  }

  return {
    skinnyBannerCookieExists : skinnyBannerCookieExists,
    closeSkinnyBanner: closeSkinnyBanner
  }
})();

repositionNewSearchBar = (cookieExists, skinnyBannerEnabled) => {
  if( cookieExists && skinnyBannerEnabled){

    let skinnyBanerHeight= $(".skinny-carousel").height();
    let newSearchTop = parseInt($(".search-results-modal.new-search-bar").css('top'), 10);
    let newTop = Math.abs(skinnyBanerHeight - newSearchTop);
    $(".search-results-modal.new-search-bar").css('top',  newTop );

  }
}

(function($) {
  
  $(document).ready(() => {
    var cookieExists = HSCO.Skinny.skinnyBannerCookieExists();
    repositionNewSearchBar(cookieExists, skinnyBannerEnabled );
  });
})(jQuery);

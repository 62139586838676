window.HSCO = window.HSCO || {};

/**
 * Handles setting dataLayer values to support Google Analytics and other 3rd party marketing tools
 * Global variable 'dataLayer' is used by Google Analytics
 * Global variable 'hscoData' is used by the rest of the site
 * Both should be maintained simultaneously
 */
HSCO.DataLayer = (function(){

	var removeCurrencySymbolFromDisplayPrice = function(price) {
		if (typeof price === "undefined" || price === null) {
			return "0.0";
		}
		price = price.replace(/[^0-9]+/g,""); // keep only digits (including cents)
		price = Number(price)/100; // back to "normal" price
		return price.toString();
	}

	var parseToJsonString = function (data) {
		if (typeof data === "undefined" || data === null) {
			return "";
		}
		return data.toString();
	}

	return {
		setEvent : function(event) {
			dataLayer.push({'event': event});
		},

		setVariable : function (name, value) {
			dataLayer.push({name: value})
		},

		setCartProducts : function (data) {
			var cartProducts = [];
			var cartSkus = [];
			var itemSku;

			new Promise(function(resolve) {
				const numOfProductEntries = data.products.length;

				if (numOfProductEntries === 0) {
					resolve();
				}

				_.each(data.products, function (product) {
					let productCategory = '';

					HSC.Util.getProductFromJcr(product.sku).then(function(productJcr) {
						productCategory = productJcr.ecomm_product_category;
					}).always(function(){
						var obj = {};
						obj.id = product.id;
						obj.name = product.display_name;
						obj.sku = product.sku;
						obj.category = productCategory;
						obj.discount = product.listPrice - product.purchasePrice;
						obj.price = product.purchasePrice;
						obj.quantity = product.quantity;
						obj.color = product.color_name;
						obj.size = product.size;
						obj.compareAtPrice = product.listPrice;
						obj.shopifyProductId = product.shopifyProductId;
						obj.shopifyVariantId = product.shopifyVariantId;

						cartProducts.push(obj);
						cartSkus.push(obj.sku);
						itemSku = product.sku;

						if (numOfProductEntries === cartProducts.length) {
							resolve();
						}
					});
				});
			}).then(function() {
				//Google AdWords
				google_tag_params.ecomm_totalvalue = data.subtotal;
				google_tag_params.ecomm_prodid = cartSkus;
				HSCO.DataLayer.setRemarketingParams();

				// HSCO Data Layer
				hscoData.cart = hscoData.cart || {};
				hscoData.cart.orderId = data.orderId;
				hscoData.cart.skus = cartSkus;
				hscoData.cart.products = cartProducts;
				hscoData.cart.quantity = data.quantity;
				hscoData.cart.subtotal = data.subtotal;
				hscoData.cart.total = data.totalPrice;
				hscoData.cart.discount = data.discount;

				//TODO: Remove when finished with old GTM
				// GTM Data Layer
				dataLayer.push({
					'cart': {
						'orderId': data.orderId,
						'products': cartProducts,
						'itemSku': itemSku,
						'skus': cartSkus,
						'quantity': data.quantity,
						'subtotal': data.subtotal,
						'total': data.totalPrice
					}
				});

				/*
				 * The dl_user_data trigger is fired from here on page load event for Authored Page (e.g. Home Page)
				 * to ensure that the required hscoData.cart.products is populated. Otherwise, the trigger returns
				 * no cart contents even if there are products in the cart.
				 */
				if (window.gtmConfig.elevarEnabled) {
					HSCO.DataLayer.setBaseLayer(Commerce.isAuthenticated());
				}
			})
		},

		//TODO Combine with setAddToCart
		addingToCart : function (data) {
			dataLayer.push({
				'cart': {
					'productSku' : data.id,
					'productPurchasePrice' : data.purchasePrice,
				}
			})
		},

		getUserProperties : function (isUserLoggedIn) {
			let visitorType = 'guest';
			let userPropertiesData = {};

			if (isUserLoggedIn) {
				visitorType = 'logged_in';

				let userData = JSON.parse(localStorage.getItem("userData"));
				userPropertiesData["customer_id"] = (typeof userData.id == null || typeof userData.id === "undefined") ? "" : userData.id;
				userPropertiesData["customer_email"] = (typeof userData.email == null || typeof userData.email === "undefined") ? "" : userData.email;
				userPropertiesData["customer_first_name"] = (typeof userData.firstName == null || typeof userData.firstName === "undefined") ? "" : userData.firstName;
				userPropertiesData["customer_last_name"] = (typeof userData.lastName == null || typeof userData.lastName === "undefined") ? "" : userData.lastName;
				userPropertiesData["customer_phone"] = (typeof userData.phoneNumber == null || typeof userData.phoneNumber === "undefined") ? "" : userData.phoneNumber;
				userPropertiesData["customer_city"] = (typeof userData.city == null || typeof userData.city === "undefined") ? "" : userData.city;
				userPropertiesData["customer_zip"] = (typeof userData.postalCode == null || typeof userData.postalCode === "undefined") ? "" : userData.postalCode;
				userPropertiesData["customer_address_1"] = (typeof userData.address1 == null || typeof userData.address1 === "undefined") ? "" : userData.address1;
				userPropertiesData["customer_address_2"] = (typeof userData.address2 == null || typeof userData.address2 === "undefined") ? "" : userData.address2;
				userPropertiesData["customer_country"] = "";
				userPropertiesData["customer_country_code"] = (typeof userData.countryCode == null || typeof userData.countryCode === "undefined") ? "" : userData.countryCode;
				userPropertiesData["customer_province"] = "";
				userPropertiesData["customer_province_code"] = (typeof userData.provinceCode == null || typeof userData.provinceCode === "undefined") ? "" : userData.provinceCode;
				userPropertiesData["customer_tags"] = "";
			}

			userPropertiesData["user_consent"] = "";
			userPropertiesData["visitor_type"] = visitorType;

			return userPropertiesData;
		},

		setRemarketingParams: function() {
			var user;
			if(Commerce.isCustomerPublic()){
				user = false;
			} else if (Commerce.isCustomerRegistered()) {
				user = true;
			} else {
				console.log("unexpected value for return user");
			}

			// The "google_tag_params" object needs to be set globally with the correct info on
			// each page prior to the event "remarketing" firing
			google_tag_params.ecomm_pagetype = hscoData.pageType;
			google_tag_params.ecomm_category = hscoData.category;
			google_tag_params.returnCustomer = user;

			// GTM Data Layer - "remarketingUS", "remarketingCA", "remarketingGB"
			dataLayer.push({
				'google_tag_params' : window.google_tag_params,
				'event': 'remarketing' + hscoData.countryCode
			});
		},

		//TODO Evaluate if still required
		setPageProductData: function(product) {
			dataLayer.push({
				'page': {
					'productSku': product.sku
				}
			});
		},

		//TODO Evaluate if still required
		setPageData: function() {
			dataLayer.push({
				'page': {
					'pageTitle' : window.dataLayer['0'].pageTitle,
					'pageCategory' : window.dataLayer['0'].category,
					'currency': window.dataLayer['0'].currency
				}
			});
		},

		//TODO Evaluate if still required
		setCategoryPageData: function() {
			dataLayer.push({
				'category': {
					'pageTitle' : window.dataLayer['0'].pageTitle,
					'pageCategory' : window.dataLayer['0'].category,
				}
			});
		},

		setAlgoliaParams: function() {
			var algoliaId = window.getCookie('_ALGOLIA');
			if (algoliaId) {
				dataLayer.push({
					'algolia': {
						'indexName': window.hscoData['algoliaIndexName'],
						'userToken': algoliaId
					}
				});
			}
		},

		setAlgoliaObjectID: function(algoliaObjectID, productPath) {
			var algoliaQueryID = localStorage.getItem('algoliaQueryID');
			var algoliaObjectPaths = localStorage.getItem('algoliaObjectPaths');
			if (algoliaQueryID && algoliaObjectPaths && JSON.parse(algoliaObjectPaths).includes(productPath)) {
				dataLayer.push({
					'algolia': {
						'queryID': algoliaQueryID,
						'objectID': algoliaObjectID
					}
				});
			} else {
				dataLayer.push({
					'algolia': {
						'objectID': algoliaObjectID
					}
				});
			}
		},

		setAddToCart: function (addToCartData){
			if (window.gtmConfig.elevarEnabled) {
				let userProperties = this.getUserProperties(Commerce.isAuthenticated());
				let productCategory = '';

				HSC.Util.getProductFromJcr(addToCartData.itemId).then(function(productJcr) {
					HSCO.DataLayer.setBaseLayer(Commerce.isAuthenticated());
					productCategory = productJcr.ecomm_product_category;
				}).always(function (){
					window.ElevarDataLayer.push({
						"event" : "dl_add_to_cart",
						"user_properties" : userProperties,
						"ecommerce": {
							"currencyCode" : window.hscoData.currency,
							"add" : {
								"actionField" : {
									"list" : location.pathname
								}, // this should be the collection page URL that user clicked product from
								"products" : [{
									"id" : addToCartData.itemId, // SKU
									"name" : addToCartData.itemName, // Product title
									"brand" : "Herschel Supply Company",
									"category" : productCategory,
									"variant" : addToCartData.itemVariant,
									"price" : parseToJsonString(addToCartData.price),
									"quantity" : parseToJsonString(addToCartData.quantity), // Not required for dl_select_item & dl_view_item
									"list" : location.pathname.substring(0, location.pathname.lastIndexOf('/')), // The list the product was discovered from
									"product_id" : parseToJsonString(addToCartData.shopifyProductId), // The product_id
									"variant_id" : parseToJsonString(addToCartData.shopifyVariantId), // id or variant_id
									"compare_at_price" : parseToJsonString(addToCartData.listPrice), // If available on dl_view_item & dl_add_to_cart otherwise use "0.0"
									"image" : ""
								}
								]
							}
						}
					});
				});
			}
		},

		setAddToCartAfterRefresh: function (addToCartData){
			if (hscoData.klaviyoEnabled) {
				let productCategory = '';
				let productPath = '';
				HSC.Util.getProductFromJcr(addToCartData.itemId).then(function(productJcr) {
					productCategory = productJcr.ecomm_product_category;
					productPath = productJcr.path;
				}).always(function (){
					let klaviyoItems = [];
					let itemCategory = '';
					let itemPath = '';
					new Promise(function(resolve) {
						const numOfProductEntries = hscoData.cart.products.length;
						if (numOfProductEntries === 0) {
							resolve();
						}
						_.each(hscoData.cart.products, function (item) {
							HSC.Util.getProductFromJcr(item.sku).then(function(productJcr) {
								itemCategory = productJcr.ecomm_product_category;
								itemPath = productJcr.path;
							}).always(function (){
								klaviyoItems.push({
									"ProductName": item.name,
									"ProductID": parseToJsonString(item.shopifyProductId),
									"VariantID": parseToJsonString(item.shopifyVariantId),
									"SKU": item.sku,
									"Category": itemCategory,
									"Color": item.color,
									"Size": item.size,
									"ImageURL": window.location.origin + "/bin/herschel/product/image." + item.sku + ".jpg",
									"ProductURL": window.location.origin + itemPath + "?v=" + item.sku,
									"Price": item.price,
									"CompareAtPrice": item.compareAtPrice,
									"Quantity": item.quantity,
									"RowTotal": Number((item.quantity * item.price).toFixed(2))
								});
								if (numOfProductEntries === klaviyoItems.length) {
									resolve();
								}
							});
						});
				   	}).then(function() {
						try {
							klaviyo.push(["track", "Added to Cart", {
								"$value": Number(removeCurrencySymbolFromDisplayPrice(hscoData.cart.subtotal)),
								"AddedItemProductName": addToCartData.itemName,
								"AddedItemProductID": parseToJsonString(addToCartData.shopifyProductId),
								"AddedItemVariantID": parseToJsonString(addToCartData.shopifyVariantId),
								"AddedItemSKU": addToCartData.itemId,
								"AddedItemCategory": productCategory,
								"AddedItemColor": addToCartData.color,
								"AddedItemSize": addToCartData.size,
								"AddedItemImageURL":  window.location.origin + "/bin/herschel/product/image." + addToCartData.itemId + ".jpg",
								"AddedItemURL": window.location.origin + productPath + "?v=" + addToCartData.itemId,
								"AddedItemPrice": addToCartData.purchasePrice,
								"AddedItemCompareAtPrice": addToCartData.listPrice,
								"AddedItemQuantity": addToCartData.quantity,
								"Items": klaviyoItems
							}]);
						} catch(e) {
							console.info(e);
						}
					});
				});
			}
		},

		setRemoveFromCart: function (removeFromCartData){
			if (window.gtmConfig.elevarEnabled) {
				let userProperties = this.getUserProperties(Commerce.isAuthenticated());
				let productCategory = '';

				HSC.Util.getProductFromJcr(removeFromCartData.itemId).then(function(productJcr) {
					HSCO.DataLayer.setBaseLayer(Commerce.isAuthenticated());
					productCategory = productJcr.ecomm_product_category;
				}).always(function () {
					window.ElevarDataLayer.push({
						"event": "dl_remove_from_cart",
						"user_properties": userProperties,
						"ecommerce": {
							"currencyCode": window.hscoData.currency,
							"remove": {
								"actionField": {
									'list': location.pathname
								}, // this should be the collection page URL that user clicked product from
								"products": [
									{
										"id": removeFromCartData.itemId, // SKU
										"name": removeFromCartData.itemName, // Product title
										"brand": "Herschel Supply Company",
										"category": productCategory,
										"variant": removeFromCartData.itemVariant,
										"price": parseToJsonString(removeFromCartData.price),
										"quantity": parseToJsonString(removeFromCartData.quantity),
										"list": "", // The list the product was discovered from
										"product_id": parseToJsonString(removeFromCartData.shopifyProductId), // The product_id
										"variant_id": parseToJsonString(removeFromCartData.shopifyVariantId), // id or variant_id
										"compare_at_price": parseToJsonString(removeFromCartData.listPrice), // If available on dl_view_item & dl_add_to_cart otherwise use "0.0"
										"image": ""
									}
								]
							}
						}
					});
				});
			}
		},

		// PLP
		setViewItemList : function (products) {
			if (window.gtmConfig.elevarEnabled) {
				const VIEW_ITEMS_EVENT = "dl_view_item_list";
				HSCO.DataLayer.setBaseLayer(Commerce.isAuthenticated());

				let impressionsData = [];
				let impressionListPosition = 0;

				_.each(products, function (product) {
					impressionsData.push({
						"id": product.analytics.sku, // SKU
						"name": product.analytics.name, // Product title
						"brand": "Herschel Supply Company",
						"category": product.analytics.category,
						"variant": product.analytics.variantName,
						"price": parseToJsonString(product.analytics.price),
						"list": location.pathname.substring(0, location.pathname.lastIndexOf('/')), // The list the product was discovered from

						"product_id": parseToJsonString(product.analytics.shopifyProductId), // The product_id
						"variant_id": parseToJsonString(product.analytics.shopifyVariantId), // id or variant_id
						"compare_at_price": parseToJsonString(product.analytics.compareAtPrice), // If available on dl_view_item & dl_add_to_cart otherwise use "0.0" (listPrice)
						"position": ++impressionListPosition // position in the list of search results, collection views and position in cart indexed starting at 1
					});
				});

				this.checkIfEventExistsInDatalayer(VIEW_ITEMS_EVENT).then((result) => {
					// Only insert if it does not exist
					if(!result){
						window.ElevarDataLayer.push({
							"event": VIEW_ITEMS_EVENT,
							"user_properties": this.getUserProperties(Commerce.isAuthenticated()),
							"ecommerce": {
								"currencyCode": window.hscoData.currency,
								"impressions": impressionsData
							}
						});
					}

				});
			}
		},

		//TODO: Remove when finished with old GTM
		setViewItem: function (product){
			dataLayer.push({
				'event' : 'view_item',
				'ecommerce': {
					'items' : [{
						'item_name' : product.title,
						'item_id' : product.id,
						'price' : product.purchasePrice,
						'item_brand' : '',
						'item_category' : '',
						'item_category2' : '',
						'item_category3' : '',
						'item_category4' : '',
						'item_category5' : '',
						'item_variant' : product.color,
						'item_list_name' : '',
						'item_list_id' : '',
						'coupon' : '', //Unavailable in this scope.
						'discount' : product.listPrice - product.purchasePrice,
						'index' : 1,
						'quantity' : 1
					}]
				}
			});
			if (hscoData.klaviyoEnabled) {
				let productCategory = '';
				let productPath = '';
				HSC.Util.getProductFromJcr(product.id).then(function(productJcr) {
					productCategory = productJcr.ecomm_product_category;
					productPath = productJcr.path;
				}).always(function () {
					let klaviyoItem = {
						"ProductName": product.title,
						"ProductID": parseToJsonString(product.shopifyProductId),
						"VariantID": parseToJsonString(product.shopifyVariantId),
						"SKU": product.id,
						"Category": productCategory,
						"Color": product.color,
						"Size": product.size,
						"ImageURL": window.location.origin + "/bin/herschel/product/image." + product.id + ".jpg",
						"URL": window.location.origin + productPath + "?v=" + product.id,
						"Brand": "Herschel Supply Company",
						"Price": product.purchasePrice,
						"CompareAtPrice": product.listPrice
					};
					try {
						klaviyo.push(["track", "Viewed Product", klaviyoItem]);
						klaviyo.push(["trackViewedItem", klaviyoItem]);
					} catch(e) {
						console.info(e);
					}
				});
			}
		},

		setSelectItem: function (skuCode, shopifyProductId, shopifyVariantId, price, listPrice, listPosition){
			if (window.gtmConfig.elevarEnabled) {
				let product = {};
				let userProperties = this.getUserProperties(Commerce.isAuthenticated());

				HSC.Util.getProductFromJcr(skuCode).then(function(productJcr) {
					HSCO.DataLayer.setBaseLayer(Commerce.isAuthenticated());
					product = productJcr;
				}).always(function () {
					window.ElevarDataLayer.push({
						"event": "dl_select_item",
						"user_properties": userProperties,
						"ecommerce": {
							"currencyCode": window.hscoData.currency,
							"click": {
								"actionField": {
									"list": location.pathname,
									"action": "click"
								}, // this should be the collection page URL that user clicked product from
								"products": [
									{
										"id": skuCode, // SKU
										"name": product.ecom_name, // Product title
										"brand": "Herschel Supply Company",
										"category": product.ecomm_product_category,
										"variant": product.ecom_colour_description,
										"price": parseToJsonString(price),
										"position": parseInt(listPosition), // Only required for dl_select_item; position in the list of search results, collection views and position in cart indexed starting at 1.
										"list": location.pathname.substring(0, location.pathname.lastIndexOf('/')), // The list the product was discovered from
										"product_id": parseToJsonString(shopifyProductId), // The product_id
										"variant_id": parseToJsonString(shopifyVariantId), // id or variant_id
										"compare_at_price": parseToJsonString(listPrice), // If available on dl_view_item & dl_add_to_cart otherwise use "0.0"
										"image": "" // If available, otherwise use an empty string (e.g. //cdn.shopify.com/small.png)
									}
								]
							}
						}
					});
				});
			}
		},

		// PLP Search results
		setSearchResults: function (products){
			if (window.gtmConfig.elevarEnabled) {
				HSCO.DataLayer.setBaseLayer(Commerce.isAuthenticated());

				let impressionsData = [];
				let impressionListPosition = 0;

				_.each(products, function (product) {
					impressionsData.push({
						"id" : product.analytics.sku, // SKU
						"name" : product.analytics.name, // Product title
						"brand" : "Herschel Supply Company",
						"category" : product.analytics.category,
						"variant" : product.analytics.variantName,
						"price" : parseToJsonString(product.analytics.price),
						"quantity" : 1, // Not required for dl_select_item & dl_view_item
						"list" : location.pathname.substring(0, location.pathname.lastIndexOf('/')), // The list the product was discovered from
						"product_id" : parseToJsonString(product.analytics.shopifyProductId), // The product_id
						"variant_id" : parseToJsonString(product.analytics.shopifyVariantId), // id or variant_id
						"compare_at_price" : parseToJsonString(product.analytics.compareAtPrice), // If available on dl_view_item & dl_add_to_cart otherwise use "0.0" (listPrice)
						"position" : ++impressionListPosition, // position in the list of search results, collection views and position in cart indexed starting at 1
					});
				});

				window.ElevarDataLayer.push({
					"event": "dl_view_search_results",
					"user_properties": this.getUserProperties(Commerce.isAuthenticated()),
					"ecommerce": {
						"currencyCode": window.hscoData.currency,
						"actionField": {
							"list": "search results"
						},
						"impressions": impressionsData
					}
				});
			}
		},

		// PDP
		setSelectItemVariant: function(product){
			if (window.gtmConfig.elevarEnabled) {
				let userProperties = this.getUserProperties(Commerce.isAuthenticated());
				let productCategory = '';

				HSC.Util.getProductFromJcr(product.id).then(function(productJcr) {
					HSCO.DataLayer.setBaseLayer(Commerce.isAuthenticated());
					productCategory = productJcr.ecomm_product_category;
				}).always(function () {
					window.ElevarDataLayer.push({
						"event": "dl_view_item",
						"user_properties": userProperties,
						"ecommerce": {
							"currencyCode": window.hscoData.currency,
							"detail": {
								"actionField": {
									"list": location.pathname,
									"action": "detail"
								}, // this should be the collection page URL that user clicked product from
								"products": [
									{
										"id": product.id, // SKU
										"name": product.title, // Product title
										"brand": "Herschel Supply Company",
										"category": productCategory,
										"variant": product.color,
										"price": parseToJsonString(product.price),
										"list": location.pathname.substring(0, location.pathname.lastIndexOf('/')), // The list the product was discovered from //TODO Get list info from Shopify?
										"product_id": parseToJsonString(product.shopifyProductId),
										"variant_id": parseToJsonString(product.shopifyVariantId),
										"compare_at_price": parseToJsonString(product.listPrice),
										"image": "", // If available, otherwise use an empty string
										"inventory": parseToJsonString(product.availableQuantity) // If available, only required on dl_view_item
									}
								]
							}
						}
					});
				});
			}
			if (hscoData.klaviyoEnabled) {
				let productCategory = '';
				let productPath = '';
				HSC.Util.getProductFromJcr(product.id).then(function(productJcr) {
					productCategory = productJcr.ecomm_product_category;
					productPath = productJcr.path;
				}).always(function () {
					let klaviyoItem = {
						"ProductName": product.title,
						"ProductID": parseToJsonString(product.shopifyProductId),
						"VariantID": parseToJsonString(product.shopifyVariantId),
						"SKU": product.id,
						"Category": productCategory,
						"Color": product.color,
						"Size": product.size,
						"ImageURL": window.location.origin + "/bin/herschel/product/image." + product.id + ".jpg",
						"URL": window.location.origin + productPath + "?v=" + product.id,
						"Brand": "Herschel Supply Company",
						"Price": product.purchasePrice,
						"CompareAtPrice": product.listPrice
					};
					try {
						klaviyo.push(["track", "Viewed Product", klaviyoItem]);
						klaviyo.push(["trackViewedItem", klaviyoItem]);
					} catch(e) {
						console.info(e);
					}
				});
			}
		},

		setViewCart: function (){
			if (hscoData.cart) {
				if (window.gtmConfig.elevarEnabled) {
					HSCO.DataLayer.setBaseLayer(Commerce.isAuthenticated());
					let impressionsData = [];
					let impressionListPosition = 0;

					_.each(hscoData.cart.products, function (product) {
						impressionsData.push({
							"id" : product.sku, // SKU
							"name" : product.name, // Product title
							"brand" : "Herschel Supply Company",
							"category" : product.category,
							"variant" : product.color,
							"price" : parseToJsonString(product.price),
							"quantity" : parseToJsonString(product.quantity), // Not required for dl_select_item & dl_view_item
							"list" : "", // The list the product was discovered from
							"product_id" : parseToJsonString(product.shopifyProductId), // The product_id
							"variant_id" : parseToJsonString(product.shopifyVariantId), // id or variant_id
							"compare_at_price" : parseToJsonString(product.compareAtPrice), // If available on dl_view_item & dl_add_to_cart otherwise use "0.0" (listPrice)
							"position" : ++impressionListPosition // position in the list of search results, collection views and position in cart indexed starting at 1
						});
					});

					window.ElevarDataLayer.push({
						"event" : "dl_view_cart",
						"user_properties" : this.getUserProperties(Commerce.isAuthenticated()),
						"cart_total" : removeCurrencySymbolFromDisplayPrice(hscoData.cart.subtotal),
						"ecommerce" : {
							"currencyCode" : window.hscoData.currency,
							"actionField" : {
								"list" : "Shopping Cart"
							},
							"impressions" : impressionsData
						}
					});
				}
			}
		},

		setSignup: function () {
			if (window.gtmConfig.elevarEnabled) {
				HSCO.DataLayer.setBaseLayer(false);

				window.ElevarDataLayer.push({
					"event" : "dl_sign_up",
					"user_properties" : this.getUserProperties(true)
				});
			}
		},

		setLogin: function () {
			HSCO.DataLayer.setEvent('login');

			if (window.gtmConfig.elevarEnabled) {
				HSCO.DataLayer.setBaseLayer(false);

				window.ElevarDataLayer.push({
					"event" : "dl_login",
					"user_properties" : this.getUserProperties(Commerce.isAuthenticated())
				});
			}
		},

		setBaseLayer: function (isUserLoggedIn) {
			let productsData = [];
			let cartSubTotal = "0.0";
			const USER_DATA_EVENT = "dl_user_data";

			if (typeof hscoData.cart !== "undefined" && hscoData.cart !== null) {
				let productCategory = '';

				_.each(hscoData.cart.products, function (product) {
					HSC.Util.getProductFromJcr(product.sku).then(function(productJcr) {
						productCategory = productJcr.ecomm_product_category;
					}).always(function (){
						productsData.push({
							"id" : product.sku, // SKU
							"name" : product.name, // Product title
							"brand" : "Herschel Supply Company",
							"category" : productCategory,
							"variant" : product.color,
							"price" : parseToJsonString(product.price),
							"quantity" : parseToJsonString(product.quantity), // Not required for dl_select_item & dl_view_item
							"list" : "", // The list the product was discovered from //TODO Get list info
							"product_id" : parseToJsonString(product.shopifyProductId), // The product_id
							"variant_id" : parseToJsonString(product.shopifyVariantId), // id or variant_id
							"compare_at_price" : parseToJsonString(product.compareAtPrice), // If available on dl_view_item & dl_add_to_cart otherwise use "0.0" (listPrice)
							"image" : "" // If available, otherwise use an empty string
						});
					});
				});

				cartSubTotal = removeCurrencySymbolFromDisplayPrice(hscoData.cart.subtotal);
			}

			// Only insert the user data event once
			this.checkIfEventExistsInDatalayer(USER_DATA_EVENT).then((result) => {
				if(!result){
					window.ElevarDataLayer.push({
						"event": USER_DATA_EVENT,
						"cart_total": cartSubTotal,
						"user_properties": this.getUserProperties(isUserLoggedIn),
						"ecommerce": {
							"cart_contents": {
								"products": productsData
							},
							"currencyCode": window.hscoData.currency
						}
					});
				}
			});
		},

		setFormSubmitNewsLetter: function () {
			var hash = Commerce.getCustomerHash();
			dataLayer.push({
				'event' : 'form_submit',
				'form_name' : 'Newsletter',
				'user_id' : hash
			});
		},

		/**
		 * Check if the event already exists in datalayer.
		 * */
		checkIfEventExistsInDatalayer: (eventName) => {
			return new Promise((resolve, reject) => {
				if (dataLayer && dataLayer.length > 0) {
					 resolve(dataLayer.some((layer) => {
						if(layer.event){
							return layer.event == eventName;
						}else{
							return false;
						}

					}));
				}else{
					reject(false);
				}
			});
		}
	}

})();

$(document).ready(function() {

	let pageType = window.hscoData.pageType;

	$('[data-modal="cart"]').on("click", function() {
		HSCO.DataLayer.setViewCart();
	});

	if (window.skus) {
		 HSCO.DataLayer.setPageData();
	}

	if (window.dataLayer['0'].pageType=='category') {
		HSCO.DataLayer.setCategoryPageData();
		HSCO.DataLayer.setEvent('categoryView');
	}

	$('[data-modal="search"]').on("click", function() {
		HSCO.DataLayer.setAlgoliaParams();
	});

	$('#hsco-subscribe').on("submit", function() {
		HSCO.DataLayer.setFormSubmitNewsLetter();
	});

	$('[data-modal="shipping"]').on("click", function() {
		HSCO.DataLayer.setEvent('view_shipping');
	});

	$('[data-modal="warranty"]').on("click", function() {
		HSCO.DataLayer.setEvent('view_warranty');
	});

	$('[data-modal="returns"]').on("click", function() {
		HSCO.DataLayer.setEvent('view_returns');
	});

	if (window.document.URL.search("/store-locator") > -1) {
		HSCO.DataLayer.setEvent('store_locator');
	}

	HSCO.DataLayer.setRemarketingParams();
	HSCO.DataLayer.setAlgoliaParams();
});

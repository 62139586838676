document.addEventListener("DOMContentLoaded", function () {
	let splitPanel = document.querySelector(".split-panel__container");

	if (splitPanel) {
		function split () {
			if (window.screen.width <= 767) {
				const nav = document.querySelector(".header.w2-header");
				const navHeight = nav.getBoundingClientRect().height;
				const heroHeight = document.querySelector(".hero-card").getBoundingClientRect().height;
				const screenHeight = window.innerHeight;
				const cardHeight = screenHeight - navHeight - heroHeight;
				const card = document.querySelector(".split-panel__card");

				if (card) {
					card.style.height = cardHeight + "px";
				}

				const centerCopy = document.querySelector(".split-panel__element__title");

				centerCopy.classList.remove("center-text");
				centerCopy.classList.add("mobile-view");
				centerCopy.style.marginLeft = centerCopy.getBoundingClientRect().width / 2 * -1 + "px";

				const titleCopy = document.querySelector(".mobile-view h1");
				titleCopy.innerText = titleCopy.innerText.toUpperCase();
			}
		}

		split();
	}
});

$(document).ready(function(){
    $('.carousel-container').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        fade: false,
        asNavFor: '.slider-nav-pdp',
        responsive: [
            {
              breakpoint: 600,
              settings: {
                arrows: false,
              }
            }
        ]
    });


    $('.slider-nav-pdp').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.carousel-container',
        dots: false,
        infinite: true,
        centerMode: true,
        arrows: true,
        focusOnSelect: true
    });
});
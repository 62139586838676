(function(){
  $(window).ready(function () {
    var cookiecount = Cookies.get('usingCookies');
    if(!cookiecount){
      $(".cookie-bar").addClass('open');
    }
  });

  $(document).on("click", '.close-cookie', function (e) {
    Cookies.set('usingCookies', 1, { path: '/' });
    $(".cookie-bar").removeClass('open');
  });

}());
